import { HTMLAttributes } from 'react';
import './Button.scss';

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  textColor?: string;
  disabled?: boolean;
}

const Button = ({ textColor, disabled = false, className, children, onClick, ...buttonProps }: ButtonProps) => (
  <button
    {...buttonProps}
    className={`button ${className ?? ''}`}
    style={{ backgroundColor: disabled ? '#ffcccc' : undefined }}
    onClick={(e) => {
      if (!disabled) {
        onClick?.(e);
      }
    }}
  >
    <span className="button-text" style={{ color: textColor }}>
      {children}
    </span>
  </button>
);

export default Button;
