import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';
import { Controller } from 'swiper/modules';
import '@/styles/onboarding/index.scss';
import 'swiper/css';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import Swiper from 'swiper';
import Button from '@/components/Button';
import LocaleSelection from '@/components/LocaleSelection/LocaleSelection';
import { useWebViewRouter } from '@/hooks/useWebViewRouter';

const PAGES = [
  {
    imageSrc: '/onboarding/screen1.webp',
    description1Id: 'onboarding1_description1',
    description2Id: 'onboarding1_description2'
  },
  {
    imageSrc: '/onboarding/screen2.webp',
    description1Id: 'onboarding2_description1',
    description2Id: 'onboarding2_description2'
  },
  {
    imageSrc: '/onboarding/screen3.webp',
    description1Id: 'onboarding3_description1',
    description2Id: 'onboarding3_description2'
  },
  {
    imageSrc: '/onboarding/screen4.webp',
    description1Id: 'onboarding4_description1',
    description2Id: 'onboarding4_description2'
  },
  {
    imageSrc: '/onboarding/screen5.webp',
    imageClassName: 'onboarding-slide-image-last',
    description1Id: 'onboarding5_description1',
    description2Id: 'onboarding5_description2'
  }
] as const;

interface PaginationProps {
  total: number;
  current: number;
}

const Pagination = ({ total, current }: PaginationProps) => (
  <div className="onboarding-pagination">
    {new Array(total).fill(0).map((v, index) => (
      <div key={index} className={`onboarding-pagination-dot${index === current ? ' onboarding-pagination-dot-current' : ''}`} />
    ))}
  </div>
);

export const OnboardingPage = () => {
  const [page, setPage] = useState(0);
  const [controlledSwiper, setControlledSwiper] = useState<Swiper | null>(null);
  const { direct } = useWebViewRouter();

  const goToNextPage = () => {
    controlledSwiper?.slideNext();
  };

  const goToPrevPage = () => {
    controlledSwiper?.slidePrev();
  };

  return (
    <div className="onboarding-container">
      <button className="onboarding-slide-button" onClick={page === 0 ? () => direct('/') : goToPrevPage}>
        <img src="/icons/arrow-right.svg" alt="arrow icon" className="onboarding-arrow onboarding-arrow-left" />
        <span className="onboarding-slide-button-text">
          <FormattedMessage id="onboarding_prev" />
        </span>
      </button>
      <div className="onboarding-slide-area">
        <Pagination total={PAGES.length + 1} current={page} />
        <ReactSwiper
          className="onboarding-slider"
          modules={[Controller]}
          controller={{ control: controlledSwiper }}
          onSwiper={(swiper) => {
            setControlledSwiper(swiper);
          }}
          slidesPerView={1}
          onSlideChange={(swiper) => {
            setPage(swiper.activeIndex);
          }}
        >
          {PAGES.map((pageInfo) => (
            <SwiperSlide key={pageInfo.imageSrc}>
              <div className="onboarding-slide-item">
                <img src={pageInfo.imageSrc} alt="screenshot" className="onboarding-slide-item-image" />
                <span className="onboarding-slide-item-description">
                  <FormattedMessage id={pageInfo.description1Id} />
                  <br />
                  <FormattedMessage id={pageInfo.description2Id} />
                </span>
              </div>
            </SwiperSlide>
          ))}
          <SwiperSlide>
            <div className="onboarding-slide-item">
              <img src="/images/bi.png" alt="logo" className="onboarding-bi-image" />
              <img src="/onboarding/screen6.png" alt="character" className="onboarding-slide-item-image-last" />
              <span className="onboarding-slide-item-description">
                <FormattedMessage id="onboarding6_description1" />
                <br />
                <FormattedMessage id="onboarding6_description2" />
              </span>
              <Button
                className="onboarding-button"
                onClick={() => {
                  direct('/photocard');
                }}
              >
                <FormattedMessage id="onboarding_button" />
              </Button>
            </div>
          </SwiperSlide>
        </ReactSwiper>
      </div>
      {page !== 5 ? (
        <button className="onboarding-slide-button" onClick={goToNextPage}>
          <img src="/icons/arrow-right.svg" alt="arrow icon" className="onboarding-arrow" />
          <span className="onboarding-slide-button-text">
            <FormattedMessage id="onboarding_next" />
          </span>
        </button>
      ) : (
        <div className="empty-100" />
      )}
      <LocaleSelection />
    </div>
  );
};
