import { AuthToken } from '@/types/data';

const useAuth = () => {
  const token = {
    access: localStorage.getItem('access') ?? '',
    refresh: localStorage.getItem('refresh') ?? ''
  };

  const isLogined = !!token.access;

  const updateToken = (partialToken: Partial<AuthToken>) => {
    localStorage.setItem('access', partialToken.access ?? '');
    localStorage.setItem('refresh', partialToken.refresh ?? '');
  };

  return { token, updateToken, isLogined };
};

export default useAuth;
