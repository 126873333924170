import { useEffect } from 'react';
import './styles/index.scss';
import { IntlProvider } from 'react-intl';
import enMsg from '@/lang/english.json';
import chMsg from '@/lang/chinese.json';
import esMsg from '@/lang/spain.json';
import jaMsg from '@/lang/japanese.json';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { HomePage } from '@/routes/index';
import useLocale from './hooks/useLocale';
import { type LocaleValue } from './constants/locale';
import { OnboardingPage } from './routes/onboarding';
import PhotocardListPage from './routes/photocard';
import OrderPage from './routes/order';
import { QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import useAuth from './hooks/useAuth';
import { issueAccessToken } from './apis/auth';
import useApi from './hooks/useApi';
import { getArtistGroups, getArtistMembers, getPhotocards } from './apis/main';
import { OrderSuccessPage } from './routes/order/success/[id]';
import { WebviewAction } from './utils/action';
import { inject } from '@vercel/analytics';
import useLocalStorage from './hooks/useLocalStorage';

const messages = { en: enMsg, ch: chMsg, es: esMsg, ja: jaMsg } satisfies Record<LocaleValue, any>;

const queryClient = new QueryClient();

const InnerApp = () => {
  const { isLogined } = useAuth();
  const queryClientFromHook = useQueryClient();
  const { fetchApi } = useApi();

  useEffect(() => {
    const prefetchPhotocards = async () => {
      const groupsResponse = await queryClientFromHook.fetchQuery({
        queryFn: () => fetchApi((accessToken) => getArtistGroups(accessToken)),
        queryKey: ['group', 'list']
      });
      if (groupsResponse.success && groupsResponse.data.groups.length > 0) {
        const selectedArtistId = groupsResponse.data.groups[0].id;
        const membersResponse = await queryClientFromHook.fetchQuery({
          queryFn: () => fetchApi((accessToken) => getArtistMembers(selectedArtistId ?? 0, accessToken)),
          queryKey: ['member', 'list', selectedArtistId]
        });
        if (membersResponse.success && membersResponse.data.members.length > 0) {
          await queryClientFromHook.prefetchInfiniteQuery({
            queryFn: ({ pageParam = undefined }) =>
              fetchApi((accessToken) => getPhotocards(selectedArtistId, undefined, pageParam, accessToken)),
            queryKey: ['card', 'list', selectedArtistId, undefined],
            getNextPageParam: (lastPage) => {
              return lastPage.data.next_page;
            }
          });
        }
      }
    };

    if (isLogined) {
      prefetchPhotocards();
    }
  }, [isLogined]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/onboarding" element={<OnboardingPage />} />
      <Route path="/photocard/*" element={<PhotocardListPage />} />
      <Route path="/order" element={<OrderPage />} />
      <Route path="/order/success/:id" element={<OrderSuccessPage />} />
    </Routes>
  );
};

function App() {
  const { locale } = useLocale();
  const { updateToken } = useAuth();
  const [searchParams] = useSearchParams();
  const [storedValue, setValue] = useLocalStorage('currency', { currency: '₩', value: 1000, text: '₩ - KRW - South Korea Won' });

  inject();

  useEffect(() => {
    setValue(storedValue);

    const init = async () => {
      const accessToken = searchParams.get('token');
      if (accessToken) return updateToken({ access: accessToken, refresh: '' });

      const deviceId = WebviewAction() ?? 'kioskdev';
      const response = await issueAccessToken(deviceId);
      updateToken({ access: response.access, refresh: response.refresh });
    };
    init();
  }, []);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <InnerApp />
        </IntlProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
