import { usePickupTime } from '@/hooks/usePickupTime';
import './PickUpBanner.scss';
import { FormattedMessage } from 'react-intl';

export const PickUpBanner = () => {
  const { pickupTime, pickupTimeTwo, pickupHour } = usePickupTime();

  return (
    <div className="pickup-banner-wrapper">
      <img src="/icons/bell.svg" alt="bell-svg" width={42} height={42} />
      <div className="pickup-banner-text-box">
        <span className="pickup-banner-title">
          <FormattedMessage id="today_pick_up_schedule" />
        </span>
        <div className="pickup-banner">
          {!pickupTimeTwo ? (
            <FormattedMessage id={`pick_up_notice_one`} values={{ pickupDate: pickupTime, pickupHour: pickupHour }} />
          ) : (
            <div className="pickup-banner-list">
              <div className="pickup-banner-list_item">
                <span className="pickup-banner-text-box_if">
                  [<FormattedMessage id={`pick_up_notice_if_one`} />]
                </span>
                <FormattedMessage id={`pick_up_notice_if_one_desc`} values={{ pickupDate: pickupTime }} />
              </div>
              <div className="pickup-banner-list_item one-line">
                <span className="pickup-banner-text-box_if">
                  [<FormattedMessage id={`pick_up_notice_if_two`} />]
                </span>
                <FormattedMessage id={`pick_up_notice_if_two_desc`} values={{ pickupDateTwo: pickupTimeTwo }} />
              </div>
              <div className="pickup-banner-list_item">
                <span className="pickup-banner-text-box_if">
                  [<FormattedMessage id={`pick_up_notice_if_one`} />]
                </span>
                <FormattedMessage id={`pick_up_notice_if_one_desc`} values={{ pickupDate: pickupTime }} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
