import { ArtistGroup, ArtistMember } from '@/types/photocard';
import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

export interface IPhotocardContext {
  group: ArtistGroup;
  member: ArtistMember | null;
  setGroup: Dispatch<SetStateAction<ArtistGroup>>;
  setMember: Dispatch<SetStateAction<any>>;
}

const initialState = {
  group: {
    id: 1,
    name: 'NCT',
    name_en: 'NCT',
    image: 'https://d1sut5kn3lwnf7.cloudfront.net/media/group/2022/03/16/76914e544c2046469779b9198712afd4.jpg'
  },
  member: {
    id: 2,
    name: '쟈니',
    name_en: 'JOHNNY',
    image: 'https://d1sut5kn3lwnf7.cloudfront.net/media/member/2022/03/16/846f0fc9edce487eaa0ec78224d16d4f.jpg'
  },
  setGroup: () => {},
  setMember: () => {}
};

export const PhotocardsContext = createContext<IPhotocardContext>(initialState);

export const PhotocardsProvider = ({ children }: { children: ReactNode }) => {
  const [group, setGroup] = useState<ArtistGroup>(initialState.group);
  const [member, setMember] = useState<ArtistMember>(initialState.member);
  return <PhotocardsContext.Provider value={{ group, member, setGroup, setMember }}>{children}</PhotocardsContext.Provider>;
};
