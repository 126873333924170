import { ApiResponse, fetchAsync } from './_util';

interface ICartResponse {
  cart_offers: number[];
}

export const getCartOffers = async (accessToken: string) => {
  return fetchAsync<ApiResponse<ICartResponse>>('GET', '/collection/kiosk/v1/cart?res_type=cart_id', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

interface ICheckPurchaseRequest {
  offer_list: number[];
  total_price: string;
}

export const postCheckPurchase = async (accessToken: string, data: ICheckPurchaseRequest) => {
  return fetchAsync<ApiResponse<any>>('POST', '/collection/kiosk/v1/check-purchase', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    body: data
  });
};

export interface ICheckRequest {
  offer_list: number[];
  payment: {
    offer_total: string;
    shipping_fee: number;
  };
  customer: {
    first_name: string;
    last_name: string;
    country_code: string;
    phone_number: number;
    email: string;
  };
}

export interface ICheckResponse {
  shipping_cost: number;
  service_fee: number;
  total_payment: number;
  destination_first_name: string;
  destination_last_name: string;
  destination_country: string;
  destination_country_code: string;
  destination_address_1: string;
  destination_address_2: string;
  destination_postal_code: string;
  destination_phone_number: string;
  shipping_memo: string;
  customer_first_name: string;
  customer_last_name: string;
  customer_email: string;
  customer_phone_number: string;
  shipping_request_id: 125;
  order_info: {
    cart_offers: number[];
    offer_total: string;
    shipping_request_id: number;
    shipping_fee: number;
    total_price: string;
    total_price_KRW: number;
    order_id: string;
  };
  payment_url: string;
}

export const postPurchase = async (accessToken: string, data: ICheckRequest) => {
  return fetchAsync<ApiResponse<ICheckResponse>>('POST', '/collection/kiosk/v1/purchase', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    body: data
  });
};
