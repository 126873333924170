import { InputHTMLAttributes, useEffect, useState } from 'react';
import './NationSelect.scss';
import { CountriesType } from '@/types/order';

interface NationSelectProps extends InputHTMLAttributes<HTMLInputElement> {
  menuClassName?: string;
  menus: CountriesType[];
  onMenuSelect?: (code: string, codeNumber: string) => void;
  defaultValue?: string;
  inputContainerClassName?: string;
  menuValue?: string;
}

export const NationSelect = ({ onMenuSelect, menuClassName, menus, menuValue }: NationSelectProps) => {
  const [isOptionVisible, setIsOptionVisible] = useState(false);
  const fistValue = menus?.filter((i) => i.code === menuValue);
  const [clickOption, setClickOption] = useState('');
  const handleClick = (name: string, codeNumber: string, code: string) => {
    setClickOption(`${name} +${codeNumber}`);
    onMenuSelect?.(code, codeNumber);
  };

  useEffect(() => {
    if (menuValue) setClickOption(`${fistValue[0].name} +${fistValue[0].number}`);
  }, [menuValue]);

  return (
    <div className="input-menu-wrap" onClick={() => setIsOptionVisible((pev) => !pev)}>
      <div className={`input-menu${menuClassName ? ` ${menuClassName}` : ''}`}>
        <span className="option-value">{menuValue ? clickOption : 'Select nation'}</span>
        {isOptionVisible && (
          <ul className="input-menu-ul">
            {menus.map((item) => (
              <li key={item.id} className="input-menu-option" onClick={() => handleClick(item.name, item.number, item.code)}>
                {`${item.name} +${item.number}`}
              </li>
            ))}
          </ul>
        )}
      </div>
      <img
        src="/icons/arrow-down.svg"
        alt="arrow icon"
        className="input-menu-arrow-down"
        style={{ transform: isOptionVisible ? 'rotate(180deg)' : 'rotate(0)' }}
      />
    </div>
  );
};

export default NationSelect;
