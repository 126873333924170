import './TermsOfServiceModal.scss';

export function TermsOfServiceModal({ onClick }: { onClick: () => void }) {
  return (
    <div className="terms-of-service-modal-wrapper">
      <div className="terms-of-service-modal-header">
        <span className="terms-of-service-modal-header-title">Terms of Service</span>
        <img
          src="/icons/close.svg"
          alt="terms-of-service-modal-close-icon"
          className="terms-of-service-modal-close-icon"
          onClick={onClick}
        />
      </div>
      <img src="/images/terms-of-service.png" alt="" />
    </div>
  );
}
