import Button from '@/components/Button';
import useCart from '@/hooks/useCart';
import { IToggleTrigger } from '@/hooks/useToggle';
import { FormattedMessage } from 'react-intl';

interface IProps {
  emptyTrigger: IToggleTrigger;
  cartTrigger: IToggleTrigger;
}

export function CartButton({ emptyTrigger, cartTrigger }: IProps) {
  const [cart] = useCart();

  return (
    <Button
      onClick={() => {
        if (cart?.length === 0) {
          emptyTrigger.open();
          setTimeout(() => emptyTrigger.close(), 800);
        } else {
          cartTrigger.open();
        }
      }}
    >
      <FormattedMessage id="photocard-list_cart" /> {`${(cart.length as number) > 0 ? `(${cart.length})` : ''}`}
    </Button>
  );
}
