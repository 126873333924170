import { format, pickupDate } from '@/utils/pickupDate';
import { useEffect, useState } from 'react';
export interface pickupStateType {
  nextDay: number;
  nextDayTwo?: number;
  pickupCase: number;
}

export const usePickupTime = () => {
  const { currentDate, hour, minute, nextWeekMonday, today, nextDay, getPickupCase } = pickupDate();
  const [pickupTime, setPickupTime] = useState('');
  const [pickupTimeTwo, setPickupTimeTwo] = useState('');
  const [pickupHour, setPickupHour] = useState<7 | 12 | 2>(12);
  const [pickupCase, setPickupCase] = useState(getPickupCase());
  const isAfterFourHour = hour >= 16; //4시 이후
  const isBetweenTime = hour === 15 && minute >= 30; //3시반 ~ 4시

  useEffect(() => {
    setPickupTimeTwo('');
    setPickupCase(getPickupCase());

    //추석
    if (today === '2024/09/13') {
      if (isAfterFourHour) {
        setPickupTime(currentDate.clone().add(6, 'days').format(format));
        setPickupHour(2);
        return;
      } else if (isBetweenTime) {
        setPickupTime(today);
        setPickupTimeTwo(currentDate.clone().add(6, 'days').format(format));
        setPickupHour(7);
        return;
      } else {
        setPickupTime(today);
        setPickupHour(7);
        return;
      }
    } else if (today > '2024/09/13' && today < '2024/09/19') {
      setPickupTime('2024/09/19');
      setPickupHour(2);
      return;
    }
    //국군의 날
    if (today === '2024/09/30') {
      if (isAfterFourHour) {
        setPickupTime(currentDate.clone().add(2, 'days').format(format));
        setPickupHour(12);
        return;
      }
      if (isBetweenTime) {
        setPickupTime(today);
        setPickupTimeTwo(currentDate.clone().add(2, 'days').format(format));
        setPickupHour(7);
        return;
      } else {
        setPickupTime(today);
        setPickupHour(7);
        return;
      }
    } else if (today === '2024/10/01') {
      setPickupTime(nextDay);
      setPickupHour(12);
      return;
    }
    //개천절
    if (today === '2024/10/02') {
      if (isAfterFourHour) {
        setPickupTime(currentDate.clone().add(2, 'days').format(format));
        setPickupHour(12);
        return;
      }
      if (isBetweenTime) {
        setPickupTime(today);
        setPickupTimeTwo(currentDate.clone().add(2, 'days').format(format));
        setPickupHour(7);
        return;
      } else {
        setPickupTime(today);
        setPickupHour(7);
        return;
      }
    } else if (today === '2024/10/03') {
      setPickupTime(nextDay);
      setPickupHour(12);
      return;
    }
    //한글날
    if (today === '2024/10/08') {
      if (isAfterFourHour) {
        setPickupTime(currentDate.clone().add(2, 'days').format(format));
        setPickupHour(12);
        return;
      }
      if (isBetweenTime) {
        setPickupTime(today);
        setPickupTimeTwo(currentDate.clone().add(2, 'days').format(format));
        setPickupHour(7);
        return;
      } else {
        setPickupTime(today);
        setPickupHour(7);
        return;
      }
    } else if (today === '2024/10/09') {
      setPickupTime(nextDay);
      setPickupHour(12);
      return;
    }
    //크리스마스
    if (today === '2024/12/24') {
      if (isAfterFourHour) {
        setPickupTime(currentDate.clone().add(2, 'days').format(format));
        setPickupHour(12);
        return;
      }
      if (isBetweenTime) {
        setPickupTime(today);
        setPickupTimeTwo(currentDate.clone().add(2, 'days').format(format));
        setPickupHour(7);
        return;
      } else {
        setPickupTime(today);
        setPickupHour(7);
        return;
      }
    } else if (today === '2024/12/25') {
      setPickupTime(nextDay);
      setPickupHour(12);
      return;
    }

    switch (pickupCase) {
      case 1:
        if (isAfterFourHour) {
          setPickupTime(nextDay);
          break;
        }
        if (isBetweenTime) {
          setPickupTime(today);
          setPickupTimeTwo(nextDay);
          break;
        } else {
          setPickupTime(today);
          break;
        }
      case 2:
        if (isAfterFourHour) {
          setPickupTime(nextWeekMonday);
          break;
        }
        if (isBetweenTime) {
          setPickupTime(today);
          setPickupTimeTwo(nextWeekMonday);
          break;
        } else {
          setPickupTime(today);
          break;
        }
      case 3:
        setPickupTime(nextWeekMonday);
    }

    setPickupHour(pickupCase === 3 || isAfterFourHour ? 12 : 7);
  }, [currentDate]);

  return { pickupTime, pickupTimeTwo, pickupCase, pickupHour };
};
