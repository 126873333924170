import { useState } from 'react';

type UseToggleProps = {
  showing?: boolean;
};

export interface IToggleTrigger {
  isShowing: boolean | undefined;
  toggle: () => void;
  close: () => void;
  open: () => void;
  setShowing: (value: boolean) => void;
}

const useToggle = ({ showing }: UseToggleProps) => {
  const [isShowing, setIsShowing] = useState(showing);

  const toggle = () => {
    setIsShowing(!isShowing);
  };

  const open = () => {
    setIsShowing(true);
  };

  const close = () => {
    setIsShowing(false);
  };

  const setShowing = (value: boolean) => {
    setIsShowing(value);
  };

  return {
    isShowing,
    toggle,
    close,
    open,
    setShowing
  };
};

export default useToggle;
