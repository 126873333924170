import { FormattedMessage } from 'react-intl';
import './CartDeleteModal.scss';

interface IProps {
  onClose?: () => void;
  onClick?: () => void;
}

const CartDeleteModal = ({ onClose, onClick }: IProps) => {
  return (
    <div className="back-modal-background">
      <div className="cart-delete-modal-container">
        <h3 className="back-modal-title">
          <FormattedMessage id="cart_delete_modal_title" />
        </h3>
        <div className="back-btn-box">
          <button className="back-btn" onClick={() => onClose?.()}>
            <FormattedMessage id="cart_delete_modal_onClose_text" />
          </button>
          <button className="back-btn" data-primary onClick={() => onClick?.()}>
            <FormattedMessage id="cart_delete_modal_onClick_text" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartDeleteModal;
