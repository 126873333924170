import { OrderSendForm } from '@/types/order';
import './CheckModal.scss';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

interface IProps {
  onClose?: () => void;
  onContinueClick?: () => void;
  total: string;
  isSubmit: boolean;
}

const CheckModal = ({ onClose, onContinueClick, total, isSubmit }: IProps) => {
  const { getValues } = useFormContext<OrderSendForm>();

  return (
    <div className="check-modal-background">
      <div className="check-modal-container">
        <div className="check-modal-title">
          <FormattedMessage id="check_modal_title" />
        </div>

        <div className="check-modal-order-data-box">
          <div className="check-modal-order-data-list">
            <span>First name</span>
            <span>{getValues('customer_first_name')}</span>
          </div>
          <div className="check-modal-order-data-list">
            <span>Last name</span>
            <span>{getValues('customer_last_name')}</span>
          </div>
          <div className="check-modal-order-data-list">
            <span>Phone number</span>
            <span>{`+${getValues('customer_country_code_number')} ${getValues('customer_phone_number')}`}</span>
          </div>
          <div className="check-modal-order-data-list">
            <span>E-mail</span>
            <span>{`${getValues('customer_email_1')}@${getValues('customer_email_2')}`}</span>
          </div>
        </div>

        <div className="check-modal-total">
          <div>Payment total</div>
          <div className="check-modal-total-value-text">{total}</div>
        </div>

        <div className="check-btn-box">
          <button className="check-btn" onClick={() => onClose?.()}>
            Return
          </button>
          <button className="check-btn" data-primary onClick={() => onContinueClick?.()} disabled={isSubmit}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckModal;
