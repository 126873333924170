import moment from 'moment';
import { daysArr } from './daysArr';

//픽업 스케줄 케이스
//1. 월 ~목
//2. 금
//3. 주말

moment.updateLocale('ko', {
  week: {
    dow: 1 // 한 주의 시작 요일은 월요일로 변경
  }
});

export const format = 'YYYY/MM/DD';

export const pickupDate = () => {
  let currentDate = moment();
  const day = daysArr[currentDate.day()];
  const hour = currentDate.hour();
  const minute = currentDate.minute();
  const today = currentDate.clone().format(format);
  const nextDay = currentDate.clone().add(1, 'days').format(format);
  const isFriday = day === '금';
  const nextWeekMonday = currentDate.clone().startOf('week').add(1, 'week').format(format);

  const getPickupCase = () => {
    if (day === '토' || day === '일') return 3;
    if (day === '금') return 2;
    return 1;
  };

  return { currentDate, day, hour, minute, nextWeekMonday, today, nextDay, isFriday, getPickupCase };
};
