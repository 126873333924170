import './OrderErrorModal.scss';
import Button from '@/components/Button';
import useReset from '@/hooks/useReset';
import { FormattedMessage } from 'react-intl';

export function OrderErrorModal({ onAgainClick }: { onAgainClick: () => void }) {
  const { resetCart, goHome } = useReset();

  return (
    <div className="order-error-page-wrapper">
      <img src="/images/bi.png" alt="bi" className="order-error-page-logo-logo" />
      <div className="order-error-container">
        <div className="order-error-talk-bubble">
          <div className="triangle" />
          <span className="order-error-talk-bubble-text">
            <FormattedMessage id="order_error_message" />
          </span>
        </div>
        <img className="order-error-character" src="/character/problem.png" alt="problem" />
      </div>
      <div className="order-error-page-decs">
        <span>
          <FormattedMessage id="order_error_desc1" />
        </span>
        <span>
          <FormattedMessage id="order_error_desc2" />
        </span>
      </div>
      <div className="order-page-btns-wrapper">
        <Button
          className="order-page-left-btn"
          textColor={'#000'}
          onClick={() => {
            resetCart();
            goHome();
          }}
        >
          <FormattedMessage id="order_button_main" />
        </Button>
        <Button className="order-page-right-btn" onClick={onAgainClick}>
          <FormattedMessage id="order_button_again" />
        </Button>
      </div>
    </div>
  );
}
