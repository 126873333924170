import { WebviewAction } from '@/utils/action';
import { track } from '@vercel/analytics';

export interface ApiResponse<T> {
  success: boolean;
  code: string;
  data: T;
}

interface ApiErrorInput {
  status?: number;
  message?: string;
  serverError?: any;
  clientError?: any;
}

export class ApiError extends Error {
  public status?: number;

  public serverError?: any;

  public clientError?: any;

  constructor(error: ApiErrorInput) {
    super(error.message);
    this.status = error.status;
    this.serverError = error.serverError;
    this.clientError = error.clientError;
  }
}

export const fetchAsync = async <T>(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  path: string,
  info?: {
    headers?: Record<string, any>;
    body?: any;
  }
): Promise<T> => {
  const response = await fetch(`${process.env.REACT_APP_PUBLIC_API_ENDPOINT}${path}`, {
    method,
    mode: 'cors',
    headers: {
      Accept: '*/*',
      'Accept-Encoding': 'gzip, deflate, br',
      Connection: 'keep-alive',
      'Content-Type': 'application/json',
      ...info?.headers
    },
    body: info?.body ? JSON.stringify(info.body) : undefined
  });

  let result;
  const deviceId = WebviewAction() ?? 'kioskdev';

  try {
    result = await response.json();
  } catch (error) {
    track(path, { device_id: deviceId, error_message: result.message, error_code: result.code });
    console.error(path, error, info);
    result = null;
    throw error;
  }

  if (response.ok) {
    return result;
  }

  track(path, { device_id: deviceId, error_message: result.message, error_code: result.code });
  throw new ApiError({
    status: response.status,
    serverError: result
  });
};
