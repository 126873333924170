'use client';

import { FormattedMessage } from 'react-intl';
import './CartModal.scss';
import Button from '../Button';
import type { PhotocardMini } from '@/types/photocard';
import { ICurrency } from '@/types/currency';
import { getPrice } from '@/utils/getPrice';
import { usePickupTime } from '@/hooks/usePickupTime';

interface CartModalProps {
  items: PhotocardMini[];
  onClose?: () => void;
  onAllItemDelete?: () => void;
  onItemDelete?: (item: PhotocardMini) => void;
  onSubmit?: (items: PhotocardMini[]) => void;
  currency: ICurrency;
  isSoloArtist: boolean;
}

const CartModal = ({ items, onClose, onAllItemDelete, onItemDelete, onSubmit, currency, isSoloArtist }: CartModalProps) => {
  const { pickupTime, pickupTimeTwo, pickupHour } = usePickupTime();

  return (
    <div
      className="cart-modal-background"
      onClick={() => {
        onClose?.();
      }}
    >
      <div className="cart-modal-information">
        <img src="/icons/bell.svg" alt="bell-svg" width={110} height={110} />
        <p className="cart-modal-information__phrase">
          <FormattedMessage id="today_pick_up_schedule" />
        </p>
        <div className="cart-modal-information__textbox">
          <div style={{ marginTop: pickupTimeTwo && 24 }}>
            <div className="cart-modal-information__text_contents">
              {pickupTimeTwo && (
                <div className="cart-modal-information_text_if_1">
                  <FormattedMessage id={`pick_up_notice_if_one`} />
                </div>
              )}
              <FormattedMessage id={`pick_up_notice_one`} values={{ pickupDate: pickupTime, pickupHour: pickupHour }} />
              {pickupTimeTwo && (
                <>
                  <div className="cart-modal-information_text_if_2" style={{ marginTop: 32 }}>
                    <FormattedMessage id={`pick_up_notice_if_two`} />
                  </div>
                  <FormattedMessage id={`pick_up_notice_if_two_desc`} values={{ pickupDateTwo: pickupTimeTwo }} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="cart-modal-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="cart-modal-header">
          <div className="cart-modal-header-top">
            <span className="cart-modal-title-text">
              <FormattedMessage id="cart-modal_title" />
            </span>
            <img
              src="/icons/close.svg"
              alt="close icon"
              className="cart-modal-close-icon"
              onClick={() => {
                onClose?.();
              }}
            />
          </div>
          <span className="cart-modal-description-text">
            <FormattedMessage id="cart-modal_description" />
          </span>
        </div>
        <div className="cart-modal-info-box">
          <span className="cart-modal-info-box-quantity-text">
            <FormattedMessage id="cart-modal_quantity" />: {items.length} <FormattedMessage id="cart-modal_items" />
          </span>
          <div
            className="cart-modal-info-box-right"
            onClick={() => {
              onAllItemDelete?.();
            }}
          >
            <img src="/icons/delete.svg" alt="delete icon" className="cart-modal-info-box-delete-icon" />
            <span className="cart-modal-info-box-delete-text">
              <FormattedMessage id="delete_all" />
            </span>
          </div>
        </div>
        <div className="cart-modal-content-container">
          {items.map((item) => (
            <div key={item.offer.id} className="cart-modal-item-container">
              <img src={item.image} alt="photocard" className="cart-modal-item-image" />
              <div className="cart-modal-item-text-area">
                <div className="cart-modal-item-info-text-area">
                  <span className="cart-modal-item-artist-text">{`${item.group.name_en} ${
                    isSoloArtist ? '' : item.member.name_en
                  }`}</span>
                  <span className="cart-modal-item-description-text">{item.name_en}</span>
                </div>
                <span className="cart-modal-item-price-text">{getPrice(item.offer.price, currency)}</span>
              </div>
              <div
                className="cart-modal-item-delete-area"
                onClick={() => {
                  onItemDelete?.(item);
                }}
              >
                <img src="/icons/delete.svg" alt="delete icon" className="cart-modal-item-delete-icon" />
              </div>
            </div>
          ))}
        </div>
        <div className="cart-modal-price-area">
          <span className="cart-modal-price-text">
            <FormattedMessage id="cart-modal_price" />:
          </span>
          <span className="cart-modal-price-text">
            {getPrice(items.reduce((prev, cur) => (prev += Number(cur.offer.price)), 0).toString(), currency)}
          </span>
        </div>
        <div className="cart-modal-button-area">
          <Button
            className="cart-modal-button"
            onClick={() => {
              onSubmit?.(items);
            }}
            disabled={items.length === 0 || items.length > 40}
          >
            <FormattedMessage id="cart-modal_proceed" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CartModal;
