import { FormattedMessage } from 'react-intl';
import './BackModal.scss';

interface IProps {
  onClose?: () => void;
  onClick?: () => void;
}

const BackModal = ({ onClose, onClick }: IProps) => {
  return (
    <div className="back-modal-background">
      <div className="back-modal-container">
        <h3 className="back-modal-title">
          <FormattedMessage id="info_modal_title" />
        </h3>
        <p className="back-modal-contents">
          <FormattedMessage id="info_modal_desc1" />
          <br />
          <FormattedMessage id="info_modal_desc2" />
        </p>
        <div className="back-btn-box">
          <button className="back-btn" onClick={() => onClick?.()}>
            <FormattedMessage id="info_modal_leave" />
          </button>
          <button className="back-btn" data-primary onClick={() => onClose?.()}>
            <FormattedMessage id="info_modal_stay" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default BackModal;
