import { useCallback, useEffect, useRef } from 'react';

const useIntersectionObserver = (onIntersect: () => void) => {
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onIntersect();
          }
        });
      },
      { threshold: 0 }
    )
  );

  const observe = useCallback((target: Element) => {
    observer.current.observe(target);
  }, []);

  const unobserve = useCallback((target: Element) => {
    observer.current.unobserve(target);
  }, []);

  return { observe, unobserve };
};

export default useIntersectionObserver;
