import { useQuery } from '@tanstack/react-query';
import './Filter.scss';
import useApi from '@/hooks/useApi';
import { getArtistMembers } from '@/apis/main';
import { useContext, useEffect } from 'react';
import { PhotocardsContext } from '@/routes/photocard/_components/Provider';
import { ArtistMember } from '@/types/photocard';

const MemberFilter = () => {
  const { fetchApi } = useApi();
  const { group, member, setMember } = useContext(PhotocardsContext);

  const { data: artistMembers } = useQuery({
    queryFn: () => fetchApi((accessToken) => getArtistMembers(group.id ?? 1, accessToken)),
    queryKey: ['member', 'list', group.id ?? 1],
    select: (data) => data.data.members
  });

  const onClick = (_member: ArtistMember) => {
    const targetArtistMember = artistMembers?.find((item) => item === _member);
    targetArtistMember && setMember(targetArtistMember);
  };

  useEffect(() => {
    artistMembers && setMember(artistMembers[0].name !== '태일' ? artistMembers[0] : artistMembers[1]);
  }, [artistMembers]);

  return (
    <div className={`filter-container photocard-list-page-artist-filter`}>
      {artistMembers?.map(
        (artist) =>
          artist.name !== '태일' && (
            <div
              key={artist.name_en}
              className={`filter-item-container${member?.name_en === artist.name_en ? ' filter-item-container-selected' : ''}`}
              onClick={() => onClick(artist)}
            >
              <span className={`filter-item-text${member?.name_en === artist.name_en ? ' filter-item-text-selected' : ''}`}>
                {artist.name_en}
              </span>
            </div>
          )
      )}
    </div>
  );
};

export default MemberFilter;
