import { OrderSendForm } from '@/types/order';
import { FormProvider, useForm } from 'react-hook-form';
import '@/styles/order/index.scss';
import { CustomerInfo, Header, PickUpNotice, ServiceAgreement } from '@/components/OrderPage';
import { Cart } from '@/components/OrderPage/Cart';
import { useTimer } from '@/hooks';

const OrderPage = () => {
  const method = useForm<OrderSendForm>({
    defaultValues: {
      same_as_above: false,
      residence_business_Hotel_etc: true,
      service_agreement_1: false,
      shipping_method: 'delivery',
      payment_method: 'cash'
    },
    mode: 'onChange'
  });
  const { ref } = useTimer();

  return (
    <div ref={ref}>
      <FormProvider {...method}>
        <div className="order-page-container">
          <div className="order-page-left-area">
            <Header />

            {/* Customer Info */}
            <CustomerInfo />

            <div className="order-page-section-divider" />
            {/* pick up notice */}
            <PickUpNotice />

            <div className="order-page-section-divider" />
            {/* Service Agreement */}
            <ServiceAgreement />
          </div>

          {/* Cart */}
          <Cart />
        </div>
      </FormProvider>
    </div>
  );
};

export default OrderPage;
