import { Locale, type LocaleValue } from '@/constants/locale';
import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

const STORAGE_KEY = 'locale';

const prevLocale = localStorage.getItem(STORAGE_KEY) as LocaleValue | null;
const localeAtom = atom<LocaleValue>(prevLocale ?? Locale.EN);

const useLocale = () => {
  const [locale, setLocale] = useAtom(localeAtom);

  const changeLocale = useCallback((newLocale: LocaleValue) => {
    setLocale(newLocale);
    localStorage.setItem(STORAGE_KEY, newLocale);
  }, []);

  return { locale, changeLocale };
};

export default useLocale;
