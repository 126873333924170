import { Locale } from '@/constants/locale';
import useLocale from '@/hooks/useLocale';
import './LocaleSelection.scss';

const LANGUAGES = [
  { iconSrc: '/icons/usa.svg', label: 'ENGLISH', locale: Locale.EN },
  { iconSrc: '/icons/china.svg', label: '简体', locale: Locale.CH },
  { iconSrc: '/icons/japan.svg', label: '日本語', locale: Locale.JA },
  { iconSrc: '/icons/spain.svg', label: 'Español', locale: Locale.ES }
] as const;

interface LanguageItemProps {
  iconSrc: string;
  label: string;
  selected: boolean;
  onClick?: () => void;
}

const LanguageItem = ({ iconSrc, label, selected, onClick }: LanguageItemProps) => (
  <button
    className={`locale-selection-item${selected ? ' locale-selection-item-selected' : ''}`}
    onClick={() => {
      onClick?.();
    }}
  >
    <img src={iconSrc} alt="language icon" className="locale-selection-item-icon" />
    <span className={`locale-selection-item-label${selected ? ' locale-selection-item-label-selected' : ''}`}>{label}</span>
  </button>
);

const LocaleSelection = () => {
  const { locale, changeLocale } = useLocale();

  return (
    <div className="locale-selection-container">
      {LANGUAGES.map((language) => (
        <LanguageItem
          key={language.label}
          iconSrc={language.iconSrc}
          label={language.label}
          selected={language.locale === locale}
          onClick={() => {
            changeLocale(language.locale);
          }}
        />
      ))}
    </div>
  );
};

export default LocaleSelection;
