import './CompanyModal.scss';

interface IProps {
  onClose?: () => void;
}

const CompanyModal = ({ onClose }: IProps) => {
  return (
    <div className="company-modal-wrapper" onClick={(e) => e.stopPropagation()}>
      <div className="company-modal-header">
        <div className="company-modal-header-text">Business information</div>
        <img src="/icons/close.svg" alt="help icon" className="company-modal-header-icon" onClick={() => onClose?.()} />
      </div>
      <div className="company-modal-decs">
        <span>Company: INFLUDEO. Inc</span>
        <span>CEO: Park Sangyeob</span>
        <span>Address: 48-24, Wausan-ro 29-gil, Mapo-gu, Seoul</span>
        <span className="company-modal-span">
          <span>Biz number: 245-81-01708</span>
          <span>Call: +82-02-6014-8373</span>
        </span>
        <span>E-commerce registration: 2022-Seoul Mapo-2978</span>
      </div>
    </div>
  );
};

export default CompanyModal;
