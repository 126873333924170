import { FormattedMessage, useIntl } from 'react-intl';
import Checkbox from '../Checkbox';
import { useFormContext, useWatch } from 'react-hook-form';
import { OrderSendForm } from '@/types/order';
import useToggle from '@/hooks/useToggle';
import PrivacyPolicyModal from '../PrivacyPolicyModal';
import TermsOfServiceModal from '../TermsOfServiceModal';

export const ServiceAgreement = () => {
  const intl = useIntl();
  const { setValue } = useFormContext<OrderSendForm>();
  const agree_check1 = useWatch({ name: 'service_agreement_1' });
  const agree_check2 = useWatch({ name: 'service_agreement_2' });

  const termsToggle = useToggle({ showing: false });
  const serviceToggle = useToggle({ showing: false });

  return (
    <div className="order-page-section">
      <div className="order-page-section-agreement-container">
        <span className="order-page-section-agreement-title">
          <FormattedMessage id="service-agreement" />
        </span>
        <div className="order-page-section-agreement-box">
          <Checkbox
            checked={agree_check1}
            label={intl.formatMessage({ id: 'order_section3_input1_label' })}
            onClick={() => setValue('service_agreement_1', !agree_check1)}
          />
        </div>
        <div className="order-page-section-agreement-caption-wrapper">
          <span className="order-page-section-agreement-caption-refund">
            <FormattedMessage id="order_page_refund_information" />
          </span>
          <span className="order-page-section-agreement-caption-text">
            · <FormattedMessage id="order_section3_input1_caption" />
          </span>
          <span className="order-page-section-agreement-caption-text">
            <FormattedMessage id="order_refund_policy" />
          </span>
        </div>
        <div className="order-page-section-agreement-box">
          <Checkbox
            checked={agree_check2}
            label={intl.formatMessage({ id: 'order_purchase_confirm' })}
            onClick={() => setValue('service_agreement_2', !agree_check2)}
          />
        </div>
        <div className="order-page-terms-of-service" onClick={termsToggle.open}>
          <span className="order-page-terms-of-service-text">Terms of Service</span>
          <img src="/icons/mark-right.svg" alt="arrow-right" className="order-page-arrow-right" />
        </div>
        {termsToggle.isShowing && <TermsOfServiceModal onClick={termsToggle.close} />}

        <div className="order-page-terms-of-service" onClick={serviceToggle.open}>
          <span className="order-page-terms-of-service-text">Privacy Policy</span>
          <img src="/icons/mark-right.svg" alt="arrow-right" className="order-page-arrow-right" />
        </div>
        {serviceToggle.isShowing && <PrivacyPolicyModal onClick={serviceToggle.close} />}
      </div>
    </div>
  );
};
