import { useCallback } from 'react';
import useAuth from './useAuth';
import { issueAccessToken, refreshAccessToken } from '@/apis/auth';
import { WebviewAction } from '@/utils/action';
import { AuthToken } from '@/types/data';

const useApi = () => {
  const { token, updateToken } = useAuth();

  const fetchApi = useCallback(
    async <T>(fetchFn: (accessToken: string) => Promise<T>) => {
      try {
        return await fetchFn(token.access);
      } catch (error) {
        let refreshResult: AuthToken;
        if (token.refresh) {
          refreshResult = await refreshAccessToken(token.refresh);
          updateToken({ access: refreshResult.access });
        } else {
          const deviceId = WebviewAction() ?? 'kioskdev';
          refreshResult = await issueAccessToken(deviceId);
          updateToken(refreshResult);
        }
        throw error;
      }
    },
    [token]
  );

  return { fetchApi };
};

export default useApi;
