import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import useCart from '@/hooks/useCart';
import { getPrice } from '@/utils/getPrice';
import useLocalStorage from '@/hooks/useLocalStorage';
import { useFormContext, useWatch } from 'react-hook-form';
import { OrderSendForm } from '@/types/order';
import useToggle from '@/hooks/useToggle';
import BackModal from '../BackModal';
import useApi from '@/hooks/useApi';
import { ICheckRequest, postCheckPurchase, postPurchase } from '@/apis/checkout';
import OrderErrorModal from '../OrderErrorModal';
import CheckModal from '../CheckModal';
import { useState } from 'react';
import { useWebViewRouter } from '@/hooks/useWebViewRouter';

export const Cart = () => {
  const { direct } = useWebViewRouter();
  const [cart] = useCart();
  const { fetchApi } = useApi();
  const [currency] = useLocalStorage('currency', null);
  const { handleSubmit, formState } = useFormContext<OrderSendForm>();
  const [isSubmit, setIsSubmit] = useState(false);

  const subtotal = cart.reduce((prev, cur) => (prev += Number(cur.offer.price) * cur.quantity), 0).toFixed();
  const shippingFee = 0;
  const now = new Date().toDateString().split(' ');
  const date = {
    day: now[2],
    month: now[1],
    year: now[3]
  };

  const { isDirty, isValid } = formState;
  const agreement1 = useWatch({ name: 'service_agreement_1' });
  const agreement2 = useWatch({ name: 'service_agreement_2' });
  const emailDomain = useWatch({ name: 'customer_email_2' });
  const disabled = !(isDirty && isValid) || !agreement1 || !agreement2 || !emailDomain;

  // toggle
  const orderErrorModal = useToggle({ showing: false });
  const backModal = useToggle({ showing: false });
  const checkModal = useToggle({ showing: false });

  // Submit
  const onSubmit = async (data: OrderSendForm) => {
    setIsSubmit(true);
    try {
      const cart_offers = cart.map((i) => i.offer.id);
      await fetchApi((token) => postCheckPurchase(token, { offer_list: cart_offers, total_price: subtotal }));
      const params: ICheckRequest = {
        offer_list: cart_offers,
        payment: {
          offer_total: subtotal,
          shipping_fee: shippingFee
        },
        customer: {
          first_name: data.customer_first_name,
          last_name: data.customer_last_name,
          country_code: data.customer_country_code,
          phone_number: data.customer_phone_number,
          email: data.customer_email_1 + '@' + data.customer_email_2
        }
      };
      const realpurchase = await fetchApi((token) => postPurchase(token, params));
      direct(`/order/success/${realpurchase.data.shipping_request_id}`);
    } catch (e) {
      setIsSubmit(false);
      orderErrorModal.open();
    }
  };

  return (
    <div className="order-page-cart">
      <div className="order-page-cart-header">
        <span className="order-page-cart-title-text">
          <FormattedMessage id="order_cart_title" />
        </span>
        <div className="order-page-cart-text-wrap">
          <span className="order-page-cart-text">
            · <FormattedMessage id="order_cart_date" />: {`${date.day} ${date.month} ${date.year}`}
          </span>
        </div>
      </div>
      <div className="order-page-cart-items-area">
        {cart.map((item) => (
          <div key={item.id} className="order-page-cart-item-container">
            <img src={item.image} alt="photocard" className="order-page-cart-item-image" />
            <div className="order-page-cart-item-text-area">
              <div className="order-page-cart-item-info-text-area">
                <span className="order-page-cart-item-artist-text">{`${item.group.name_en} ${
                  item.group.name_en === item.member.name_en ? '' : item.member.name_en
                }`}</span>
                <span className="order-page-cart-item-description-text">{item.name_en}</span>
              </div>
              <div className="order-page-cart-item-price-area">
                <span className="order-page-cart-item-price-text">{getPrice(item.offer.price, currency)}</span>
                <span className="order-page-cart-item-quantity-text">
                  <FormattedMessage id="order_cart_quantity" />: {item.quantity}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="order-page-cart-result-area">
        <div className="order-page-cart-result-item">
          <span className="order-page-cart-result-item-text">
            <FormattedMessage id="order_cart_quantity" />:
          </span>
          <span className="order-page-cart-result-item-text">
            {cart.length} <FormattedMessage id="order_cart_items" />
          </span>
        </div>
        <div className="order-page-cart-result-item">
          <span className="order-page-cart-result-item-total-text">
            <FormattedMessage id="order_cart_total" />
          </span>
          <span className="order-page-cart-result-item-total-text">{`${getPrice(Number(subtotal).toString(), currency)}`}</span>
        </div>
      </div>
      <div className="order-page-cart-button-area">
        <Button className="order-page-cart-back-button" textColor="#000000" onClick={backModal.open}>
          <FormattedMessage id="order_cart_back" />
        </Button>
        <Button className="order-page-cart-next-button" disabled={disabled} onClick={checkModal.open}>
          <FormattedMessage id="order_cart_next" />
        </Button>
      </div>

      {checkModal.isShowing && (
        <CheckModal
          onClose={checkModal.close}
          onContinueClick={handleSubmit(onSubmit)}
          total={`${getPrice(Number(subtotal).toString(), currency)}`}
          isSubmit={isSubmit}
        />
      )}
      {backModal.isShowing && <BackModal onClose={backModal.close} onClick={() => direct('/photocard')} />}
      {orderErrorModal.isShowing && <OrderErrorModal onAgainClick={orderErrorModal.close} />}
    </div>
  );
};
