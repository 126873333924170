import { useEffect, useMemo, useRef, useState } from 'react';
import useReset from './useReset';

export const useTimer = () => {
  const initialTime = 600;
  const { resetCart, goHome } = useReset();
  const [time, setTime] = useState<number>(initialTime);
  const ref = useRef<HTMLDivElement>(null);

  const timeOut = useMemo(() => {
    if (time === 0) {
      resetCart();
      goHome();
    }
  }, [time]);

  useEffect(() => {
    if (!ref) return;
    ref.current?.addEventListener('click', () => {
      setTime(initialTime);
    });
  }, [ref.current]);

  useEffect(() => {
    const timerFunc = setInterval(() => {
      setTime((prev) => {
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timerFunc);
  }, []);
  return { ref };
};
