import useLocalStorage from '@/hooks/useLocalStorage';
import { Photocard } from '@/types/photocard';
import { getPrice } from '@/utils/getPrice';
import './PhotocardItem.scss';

interface IProps {
  photocard: Photocard;
  onClick: () => void;
  isSoloArtist: boolean;
}

export const PhotocardItem = ({ photocard, onClick, isSoloArtist }: IProps) => {
  const [currency] = useLocalStorage('currency', null);
  return (
    <>
      <div className="photocard-list-item" onClick={() => onClick()}>
        <img src={photocard.image} alt="photocard" className="photocard-list-item-image" />
        <span className="photocard-list-item-artist-text">
          {`${photocard.group.name_en} ${!isSoloArtist ? photocard.member?.name_en : ''}`}
        </span>
        <span className="photocard-list-item-description-text">{photocard.name_en}</span>
        <span className="photocard-list-item-price-text">{getPrice(photocard.offer.price, currency)}</span>
      </div>
    </>
  );
};

export default PhotocardItem;
