import { FormattedMessage } from 'react-intl';
import './NothingCard.scss';

export const NothingCard = ({ className = '' }: { className?: string }) => {
  return (
    <div className={`${className} nothing-card-wrapper`}>
      <img src="/icons/out-of-stock.svg" alt="sort-svg" />
      <span>
        <FormattedMessage id="out_of_stock" />
      </span>
    </div>
  );
};
