import type { ArtistGroup, ArtistMember, Photocard } from '@/types/photocard';
import { ApiResponse, fetchAsync } from './_util';
import { CountriesType } from '@/types/order';

interface GetArtistGroupsResponse {
  groups: ArtistGroup[];
}

export const getArtistGroups = async (accessToken?: string) =>
  fetchAsync<ApiResponse<GetArtistGroupsResponse>>('GET', '/card/gb/v1/group', {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined
    }
  });

interface GetCartOffersResponse {
  cart_offers: number[];
}

export const getCartOffers = async (accessToken?: string) =>
  fetchAsync<ApiResponse<GetCartOffersResponse>>('GET', '/collection/kiosk/v1/cart', {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined
    }
  });

interface GetArtistMembersResponse {
  members: ArtistMember[];
}

export const getArtistMembers = async (groupId: number, accessToken?: string) =>
  fetchAsync<ApiResponse<GetArtistMembersResponse>>('GET', `/card/gb/v1/group/${groupId}/member`, {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined
    }
  });

interface GetPhotocardsResponse {
  next: string | null;
  previous: string | null;
  results: Photocard[];
  next_page: string | null;
  previous_page: string | null;
}

export const getPhotocards = async (groupId?: number, memberId?: number, nextPage?: string, accessToken?: string) =>
  fetchAsync<ApiResponse<GetPhotocardsResponse>>(
    'GET',
    `/card/kiosk/v1/search?${groupId ? `group=${groupId}` : ''}${memberId ? `&member=${memberId}` : ''}${
      nextPage ? `&page=${nextPage}` : ''
    }`,
    {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined
      }
    }
  );

interface getCartResponse {
  success: true;
  code: string;
  data: {
    cart_offers: getDatas[];
  };
}

export interface getDatas {
  photo_card: {
    id: number;
    name: string;
    name_en: string;
    image: string;
    member: ArtistMember;
    group: ArtistGroup;
  };
  offer: {
    id: number;
    price: string;
  };
}

export const getCart = async (accessToken?: string) =>
  fetchAsync<getCartResponse>('GET', `/collection/kiosk/v1/cart`, {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined
    }
  });

type AddToCartResponse =
  | {
      success: true;
      code: 'OK';
      data: {
        added_offer: {
          photo_card: {
            id: number;
            name: string;
            name_en: string;
            image: string;
            member: ArtistMember;
            group: ArtistGroup;
          };
          offer: {
            id: number;
            price: string;
          };
        };
      };
    }
  | {
      success: false;
      code: string;
      data: {};
      detail: string;
    };

export const addToCart = async (offerId: number, accessToken?: string) =>
  fetchAsync<AddToCartResponse>('POST', `/collection/kiosk/v1/cart`, {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined
    },
    body: {
      offer_id: offerId
    }
  });

type RemoveFromCartResponse =
  | {
      success: true;
      code: 'OK';
      data: {
        deleted_offers: number[];
      };
    }
  | {
      success: false;
      code: string;
      data: {
        invalid_offers: number[];
      };
      detail: string;
    };

export const removeFromCart = async (offerList: number[], accessToken?: string) =>
  fetchAsync<RemoveFromCartResponse>('DELETE', `/collection/kiosk/v1/cart`, {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined
    },
    body: {
      offer_list: offerList
    }
  });

interface CheckPurchaseResponse {
  success: boolean;
  code: string;
}

export const checkPurchase = async (offerList: number[], totalPrice: number, accessToken?: string) =>
  fetchAsync<CheckPurchaseResponse>('POST', `/collection/kiosk/v1/check-purchase`, {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined
    },
    body: {
      offer_list: offerList,
      total_price: `${totalPrice.toFixed(2)}`
    }
  });

interface GetCountriesResponse {
  countries: CountriesType[];
}

export const getCountries = async (accessToken?: string) =>
  fetchAsync<ApiResponse<GetCountriesResponse>>('GET', '/collection/gb/v1/shipping/countries', {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined
    }
  });

export const deleteCache = async (accessToken?: string) =>
  fetchAsync('POST', '/payment/kiosk/v1/clear-cache', {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined
    },
    body: {}
  });
