import { type InputHTMLAttributes } from 'react';
import './Input.scss';
import { UseFormRegisterReturn } from 'react-hook-form';
import { CountriesType } from '@/types/order';
import NationSelect from '../NationSelect';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorMessage?: string;
  containerClassName?: string;
  register?: UseFormRegisterReturn;
  letterLabel?: string;
}

const Input = ({
  label,
  errorMessage,
  containerClassName,
  className,
  register,
  type,
  letterLabel,
  ...inputProps
}: InputProps) => (
  <div className={`input-container ${containerClassName ? ` ${containerClassName}` : ''}`}>
    {label && <span className="input-label-text">{label}</span>}
    <input
      {...inputProps}
      {...register}
      type={type ? type : 'text'}
      className={`input${errorMessage ? ' input-error' : ''}${className ? ` ${className}` : ''}`}
    />
    {errorMessage && <span className="input-error-text">{errorMessage}</span>}
  </div>
);

interface InputWithMenuProps extends InputProps {
  menuClassName?: string;
  menus: CountriesType[];
  onMenuSelect?: (value: string, codeNumber: string) => void;
  defaultValue?: string;
  inputContainerClassName?: string;
  menuValue?: string;
}

export const InputWithMenu = ({
  menuClassName,
  menus,
  onMenuSelect,
  menuValue,
  defaultValue,
  inputContainerClassName,
  label,
  errorMessage,
  containerClassName,

  ...inputProps
}: InputWithMenuProps) => (
  <div className={`input-container${containerClassName ? ` ${containerClassName}` : ''}`}>
    {label && <span className="input-label-text">{label}</span>}
    <div className="input-menu-container">
      <NationSelect menus={menus} menuClassName={menuClassName} onMenuSelect={onMenuSelect} menuValue={menuValue} />
      <Input {...inputProps} containerClassName={inputContainerClassName} type={'number'} />
    </div>
    {errorMessage && <span className="input-error-text">{errorMessage}</span>}
  </div>
);

export default Input;
