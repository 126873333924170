import { FormattedMessage } from 'react-intl';

export const Header = () => {
  return (
    <div className="order-page-fixed-area">
      <div className="order-page-header">
        <span className="order-page-header-text">
          <FormattedMessage id="order_title" />
        </span>
      </div>
      <div className="order-page-contents">
        <img src="/icons/notice.svg" alt="notice-icon" />
        <FormattedMessage id="order_notification" />
      </div>
    </div>
  );
};
