import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getArtistGroups } from '@/apis/main';
import { ArtistGroup } from '@/types/photocard';
import useApi from '@/hooks/useApi';
import { PhotocardsContext } from '@/routes/photocard/_components/Provider';
import './index.scss';

export function GroupFilter() {
  const { fetchApi } = useApi();
  const { group, setGroup } = useContext(PhotocardsContext);

  const { data: artistGroups } = useQuery({
    queryFn: () => fetchApi((accessToken) => getArtistGroups(accessToken)),
    queryKey: ['group', 'list'],
    select: (data) => data.data.groups
  });

  const onClick = (grp: ArtistGroup) => setGroup(grp);

  return (
    <div className="photocard-list-page-artist-list-container">
      {artistGroups?.map((artist: ArtistGroup) => (
        <div
          key={`${artist.name_en}`}
          className={`photocard-list-page-artist-item-container${
            artist.name_en === group.name_en ? ' photocard-list-page-artist-item-container-selected' : ''
          }`}
          onClick={() => onClick(artist)}
        >
          <img src={artist.image} alt="artist logo" className="photocard-list-page-artist-item-image" />
          <span
            className={`photocard-list-page-artist-item-text${
              artist.name_en === group.name_en ? ' photocard-list-page-artist-item-text-selected' : ''
            }`}
          >
            {artist.name_en}
          </span>
        </div>
      ))}
    </div>
  );
}
