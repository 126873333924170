import { addToCart, getArtistMembers, getPhotocards } from '@/apis/main';
import useApi from '@/hooks/useApi';
import { PhotocardsContext } from '@/routes/photocard/_components/Provider';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PhotocardItem from '../PhotocardItem';
import { Photocard } from '@/types/photocard';
import useToggle from '@/hooks/useToggle';
import { Loading } from '../Loading';
import { NothingCard } from '../NothingCard/NothingCard';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import PhotocardModal from '@/routes/photocard/[id]';
import useLocalStorage from '@/hooks/useLocalStorage';
import useCart from '@/hooks/useCart';

export function CardsList() {
  const { fetchApi } = useApi();
  const [currency] = useLocalStorage('currency', null);
  const contentRef = useRef<HTMLDivElement>(null);
  const lastElement = useRef<HTMLDivElement>(null);
  const outOfStockModal = useToggle({ showing: false });
  const observerModal = useToggle({ showing: false });
  const { observe, unobserve } = useIntersectionObserver(() => observerModal.open());
  const { group, member } = useContext(PhotocardsContext);
  const [cart, setCart] = useCart();
  const [selected, setSelected] = useState<Photocard | null>(null);

  const {
    data: cardsData,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchCardsData
  } = useInfiniteQuery({
    queryFn: ({ pageParam = undefined }) =>
      fetchApi((accessToken) => getPhotocards(group?.id, member ? member.id : undefined, pageParam, accessToken)),
    queryKey: ['card', 'list', group?.id, member ? member.name_en : undefined],
    getNextPageParam: (lastPage) => {
      return lastPage.data.next_page;
    }
  });

  const { data: artistMembers } = useQuery({
    queryFn: () => fetchApi((accessToken) => getArtistMembers(group.id ?? 1, accessToken)),
    queryKey: ['member', 'list', group.id ?? 1],
    select: (data) => data.data.members
  });

  const addCartMutation = useMutation({
    mutationFn: (offerId: number) => fetchApi((accessToken) => addToCart(offerId, accessToken)),
    mutationKey: ['cart', 'add'],
    onSuccess: (data) => {
      if (data.success) {
        refetchCardsData();
        setCart((prev) => {
          const prevItemIndex = prev.findIndex((item) => item.offer.id === data.data.added_offer.offer.id);
          if (prevItemIndex !== -1) {
            const newItem = [...prev];
            newItem[prevItemIndex] = { ...newItem[prevItemIndex], quantity: newItem[prevItemIndex].quantity + 1 };
            return newItem;
          }
          return [...prev, { ...data.data.added_offer.photo_card, offer: data.data.added_offer.offer, quantity: 1 }];
        });
        setTimeout(() => {
          setSelected(null);
        }, 500);
      }
    },
    onError: () => {}
  });

  const moveTop = useCallback(() => {
    contentRef?.current?.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [contentRef]);

  const cards = useMemo(() => {
    const results = cardsData?.pages.reduce((prev, cur) => prev.concat(cur.data.results), [] as Photocard[]);
    results && results.length < 1 ? outOfStockModal.open() : outOfStockModal.close();
    if (isLoading) moveTop();
    return results;
  }, [cardsData, isLoading]);

  const isSoloArtist = useMemo(() => artistMembers?.length === 1, [artistMembers?.length]);

  useEffect(() => {
    const getNext = async () => {
      if (observerModal.isShowing && !isFetchingNextPage && lastElement.current) {
        unobserve(lastElement.current);
        if (hasNextPage) {
          await fetchNextPage();
        }
        observerModal.close();
        observe(lastElement.current);
      }
    };
    getNext();
  }, [observerModal]);

  useEffect(() => {
    if (lastElement.current) {
      observe(lastElement.current);
    }
    return () => {
      if (lastElement.current) {
        unobserve(lastElement.current);
      }
    };
  }, []);

  return (
    <>
      <div className="photocard-list-area" ref={contentRef}>
        {cards?.map((photocard) => (
          <PhotocardItem
            key={photocard.id}
            photocard={photocard}
            onClick={() => {
              !isLoading && setSelected(photocard);
            }}
            isSoloArtist={isSoloArtist}
          />
        ))}
        <div ref={lastElement} />

        {isLoading && <Loading className="full-loading" />}
        {!isLoading && isFetchingNextPage && !outOfStockModal.isShowing && <Loading className="bottom-loading" />}
        {!isLoading && !isFetchingNextPage && outOfStockModal.isShowing && <NothingCard />}
      </div>

      {selected && (
        <PhotocardModal
          {...selected}
          onAddToCart={(offerId) => {
            if (cart.length < 40) addCartMutation.mutate(offerId);
          }}
          onClose={() => {
            setSelected(null);
          }}
          currency={currency}
        />
      )}
    </>
  );
}
