import { usePickupTime } from '@/hooks/usePickupTime';
import useReset from '@/hooks/useReset';
import '@/styles/order/success.scss';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

export function OrderSuccessPage() {
  const intl = useIntl();
  const params = useParams();
  const [time, setTime] = useState(10);
  const { resetCart, goHome } = useReset();
  const { pickupTime, pickupHour } = usePickupTime();

  useEffect(() => {
    const id = setInterval(() => {
      setTime((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (time === 0) {
      resetCart();
      goHome();
    }
  }, [time]);

  return (
    <div className="order-success-page-wrapper">
      <div className="order-success-container">
        <div className="order-success-talk-bubble">
          <div className="triangle" />
          <span className="order-success-talk-bubble-text">
            <FormattedMessage id="order_success_title_1" />
          </span>
          <span className="order-success-talk-bubble-text">
            <FormattedMessage id="order_success_title_2" />
          </span>
        </div>
        <div className="order-success-img-box">
          <img className="order-success-character" src="/character/complete.png" alt="problem" />
          <div className="order-success-number-box">
            <p className="order-success-text-box">
              <span>
                <FormattedMessage id="order_cart_id" />
              </span>
              <span className="order-success-bold-text">{params.id}</span>
            </p>
            <p className="order-success-text-box">
              <span>
                <FormattedMessage id="available_pickup_schedule" />
              </span>
              <span className="order-success-time-text">{`${pickupTime} ${pickupHour}:00PM ~`}</span>
            </p>
          </div>
        </div>
      </div>

      <div className="order-success-page-decs">
        <span>
          <FormattedMessage id="order_success_order-info" />
        </span>
        <span>
          <FormattedMessage id="order_success_order-info2" />
        </span>
      </div>
      <span className="order-success-explanation">
        {intl.formatMessage({ id: 'order_success_order-info3' })} <span>{time}</span>
        <FormattedMessage id="order_success_order-info4" />
      </span>
    </div>
  );
}
