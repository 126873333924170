import './Checkbox.scss';

interface CheckboxProps {
  checked: boolean;
  label?: string;
  onClick?: () => void;
}

const Checkbox = ({ checked, label, onClick }: CheckboxProps) => (
  <div className="checkbox-container" onClick={onClick}>
    <img src={checked ? '/icons/checkbox_on.svg' : 'icons/checkbox_off.svg'} alt="checkbox icon" className="checkbox-image" />
    {label && <span className="checkbox-label-text">{label}</span>}
  </div>
);

export default Checkbox;
