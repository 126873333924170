import { useNavigate, useSearchParams } from 'react-router-dom';

export const useWebViewRouter = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') ?? '';

  const firstString = (url: string) => (/\?/g.test(url) ? '&' : '?');

  const webViewSearchParams = (url: string) => `${url}${!!token ? `token=${token}` : ''}`;

  const direct = (url: string) => {
    navigate(encodeURI(`${url}${webViewSearchParams(firstString(url))}`));
  };
  const replace = (url: string) => {
    navigate(encodeURI(`${url}${webViewSearchParams(firstString(url))}`));
  };

  return { direct, replace, token };
};
