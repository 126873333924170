import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import '@/styles/index.scss';
import Button from '@/components/Button';
import LocaleSelection from '@/components/LocaleSelection/LocaleSelection';
import { useWebViewRouter } from '@/hooks/useWebViewRouter';

export const HomePage = () => {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const { direct } = useWebViewRouter();

  if (isClicked)
    return (
      <div className="home-container">
        <img src="/images/bi.png" alt="bi" className="home-logo" />
        <div className="home-talk-bubble">
          <div className="triangle" />
          <span className="home-talk-bubble-text">
            <FormattedMessage id="onboarding_speechBubble" />
          </span>
        </div>
        <img src="/character/onboard.png" alt="character" className="home-character" />
        <span className="home-description-text">
          <FormattedMessage id="onboarding_description1" />
          <br />
          <FormattedMessage id="onboarding_description2" />
        </span>
        <div onClick={() => direct('/onboarding')}>
          <Button className="home-onboarding-button">
            <FormattedMessage id="onboarding_button" />
          </Button>
        </div>
        <LocaleSelection />
      </div>
    );

  return (
    <div className="home-container" onClick={() => setIsClicked(true)}>
      <div className="home-information">
        <h3>The Marketplace for K-pop</h3>
        <div className="title">Photocards</div>
        <div className="home-deco-box">
          <img className="home-deco-main" src="/home-wrapper.png" alt="home-wrapper" />
          <img className="home-deco" src="/home-wrapper-deco.png" alt="home-wrapper-deco" />
        </div>

        <div className="home-tab-screen">
          <span className="blink"></span> <span className="home-tab-screen-text">Please tap the screen!</span>
        </div>
      </div>
    </div>
  );
};
