import useApi from '@/hooks/useApi';
import useCart from '@/hooks/useCart';
import { useMutation } from '@tanstack/react-query';
import { deleteCache, removeFromCart } from '@/apis/main';

export const useReset = () => {
  const { fetchApi } = useApi();
  const [, setCart] = useCart();

  const deleteCacheMutation = useMutation({
    mutationFn: () => fetchApi((accessToken) => deleteCache(accessToken)),
    mutationKey: ['cache', 'delete']
  });

  const deleteCartMutation = useMutation({
    mutationFn: (offerList: number[]) => fetchApi((accessToken) => removeFromCart(offerList, accessToken)),
    mutationKey: ['cart', 'remove']
  });

  const resetCart = () => {
    deleteCartMutation.mutate([]);
    deleteCacheMutation.mutate();
    setCart([]);
  };

  const goHome = () => {
    window.location.href = '/';
  };

  return { resetCart, goHome };
};

export default useReset;
