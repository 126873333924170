import Button from '@/components/Button';
import { FormattedMessage } from 'react-intl';
import '@/styles/photocard/[id].scss';
import { Photocard } from '@/types/photocard';
import { getPrice } from '@/utils/getPrice';

interface PhotocardModalProps extends Photocard {
  onClose?: () => void;
  onAddToCart?: (offerId: number) => void;
  currency: { currency: '$' | '₩'; value: number; text: string };
}

const PhotocardDetailPage = ({ onClose, onAddToCart, currency, ...photocard }: PhotocardModalProps) => {
  return (
    <div
      className="photocard-detail-background"
      onClick={(e) => {
        onClose?.();
      }}
    >
      <div
        className="photocard-detail-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="photocard-detail-top-area">
          <div className="photocard-detail-header">
            <span className="photocard-detail-header-title-text">
              <FormattedMessage id="photocard-detail_title" />
            </span>
            <img
              src="/icons/close.svg"
              alt="close icon"
              className="photocard-detail-header-close-icon"
              onClick={() => {
                onClose?.();
              }}
            />
          </div>
          <div className="photocard-detail-content-area">
            <img src={photocard.image} alt="photocard" className="photocard-detail-image" />
            <div>
              <p className="photocard-detail-group-text">{photocard.group.name_en}</p>
              <p className="photocard-detail-artist-text">{photocard.member.name_en}</p>
              <p className="photocard-detail-name-text">{photocard.name_en}</p>
              <div className="photocard-detail-tag-wrap">
                <div className="photocard-detail-tag-container">
                  <span className="photocard-detail-tag-text">{photocard.kind.name_en}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="photocard-detail-price-area">
          <span className="photocard-detail-price-text">
            <FormattedMessage id="photocard-detail_price" />:
          </span>
          <span className="photocard-detail-price-text">{getPrice(photocard.offer.price, currency)}</span>
        </div>
        <div className="photocard-detail-button-area">
          <Button
            className="photocard-detail-button"
            onClick={() => {
              onAddToCart?.(photocard.offer.id);
            }}
          >
            <FormattedMessage id="photocard-detail_cart" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PhotocardDetailPage;
