import '@/styles/photocard/list.scss';
import CartModal from '@/components/CartModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getCart, removeFromCart } from '@/apis/main';
import useCart from '@/hooks/useCart';
import useApi from '@/hooks/useApi';
import useReset from '@/hooks/useReset';
import useLocalStorage from '@/hooks/useLocalStorage';
import useToggle from '@/hooks/useToggle';
import CartDeleteModal from '@/components/CartDeleteModal';

import { PhotocardsProvider } from './_components/Provider';
import { GNB } from '@/components/GNB';
import { GroupFilter } from '@/components/GroupFilter';
import MemberFilter from '@/components/Filter/Filter';
import { CardsList } from '@/components/CardsList';
import { CartButton } from '@/components/CartButton';
import { FormattedMessage } from 'react-intl';
import { PickUpBanner } from '@/components/PickUpBanner/PickUpBanner';
import { useTimer } from '@/hooks';
import { useWebViewRouter } from '@/hooks/useWebViewRouter';

const PhotocardListPage = () => {
  const { direct } = useWebViewRouter();
  const { resetCart } = useReset();
  const { fetchApi } = useApi();
  const [currency] = useLocalStorage('currency', null);
  const { ref } = useTimer();

  //state
  const [cart, setCart] = useCart();

  // toggle
  const companyModal = useToggle({ showing: false });
  const cartModal = useToggle({ showing: false });
  const emptyCartModal = useToggle({ showing: false });
  const deleteModal = useToggle({ showing: false });

  const { data: cartData } = useQuery({
    queryFn: () => fetchApi((accessToken) => getCart(accessToken)),
    queryKey: ['cart', 'get'],
    select: (data) => data.data.cart_offers
  });

  const deleteCartMutation = useMutation({
    mutationFn: (offerList: number[]) => fetchApi((accessToken) => removeFromCart(offerList, accessToken)),
    mutationKey: ['cart', 'remove'],
    onSuccess: (data) => {
      if (data.success) {
        setCart((prev) =>
          prev.filter((cartItem) =>
            data.data.deleted_offers.reduce((prev, cur) => (cur === cartItem.offer.id ? false : prev), true)
          )
        );
      }
    }
  });

  return (
    <PhotocardsProvider>
      <div
        ref={ref}
        className="photocard-list-page-container"
        onClick={() => {
          companyModal.isShowing && companyModal.close();
        }}
      >
        <GNB cartData={cartData} companyModal={companyModal} />

        <GroupFilter />
        <div className="photocard-list-page-main">
          <MemberFilter />

          <CardsList />

          <div className="photocard-list-bottom-area">
            <PickUpBanner />
            <CartButton emptyTrigger={emptyCartModal} cartTrigger={cartModal} />
          </div>

          {emptyCartModal.isShowing && (
            <div className="empty-cart-modal-wrapper">
              <FormattedMessage id="card-modal_empty" />
            </div>
          )}
        </div>

        {cartModal.isShowing && (
          <CartModal
            isSoloArtist={false}
            currency={currency}
            items={cart}
            onClose={() => cartModal.close()}
            onItemDelete={(item) => deleteCartMutation.mutate([item.offer.id])}
            onAllItemDelete={() => deleteModal.open()}
            onSubmit={() => {
              if (cart.length > 0) {
                direct('/order');
              } else {
                cartModal.close();
                emptyCartModal.open();
                setTimeout(() => {
                  emptyCartModal.close();
                }, 800);
              }
            }}
          />
        )}
        {deleteModal.isShowing && (
          <CartDeleteModal
            onClick={() => {
              resetCart();
              deleteModal.close();
            }}
            onClose={() => deleteModal.close()}
          />
        )}
      </div>
    </PhotocardsProvider>
  );
};

export default PhotocardListPage;
