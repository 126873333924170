import { fetchAsync } from './_util';

interface IssueAccessTokenResponse {
  access: string;
  refresh: string;
}

export const issueAccessToken = async (device_id: string) =>
  fetchAsync<IssueAccessTokenResponse>('POST', '/token/kiosk', {
    body: {
      device_id
    }
  });

interface RefreshAccessTokenResponse {
  access: string;
}

export const refreshAccessToken = async (refresh: string) =>
  fetchAsync<RefreshAccessTokenResponse>('POST', '/accounts/token/refresh', {
    body: {
      refresh
    }
  });
