import { Locale } from '@/constants/locale';
import useLocale from '@/hooks/useLocale';
import './LocaleSelectionSimple.scss';
import { FormattedMessage } from 'react-intl';

const LANGUAGES = [
  { iconSrc: '/icons/usa.svg', locale: Locale.EN },
  { iconSrc: '/icons/china.svg', locale: Locale.CH },
  { iconSrc: '/icons/japan.svg', locale: Locale.JA },
  { iconSrc: '/icons/spain.svg', locale: Locale.ES }
] as const;

interface LanguageItemProps {
  iconSrc: string;

  selected: boolean;
  onClick?: () => void;
}

const LanguageItem = ({ iconSrc, selected, onClick }: LanguageItemProps) => (
  <button
    className={`locale-selection-item-simple${selected ? ' locale-selection-item-simple-selected' : ''}`}
    onClick={() => {
      onClick?.();
    }}
  >
    <img src={iconSrc} alt="language icon" className="locale-selection-item-simple-icon" />
  </button>
);

const LocaleSelectionSimple = () => {
  const { locale, changeLocale } = useLocale();

  return (
    <div className="locale-selection-container-simple">
      <div className="local-selection-simple-title">
        <FormattedMessage id="language" /> :
      </div>
      {LANGUAGES.map((language, idx) => (
        <LanguageItem
          key={idx}
          iconSrc={language.iconSrc}
          selected={language.locale === locale}
          onClick={() => {
            changeLocale(language.locale);
          }}
        />
      ))}
    </div>
  );
};

export default LocaleSelectionSimple;
